<template>
  <div>
    <div v-if="!isLaptop">
      <MobileStudentLogin v-if="AllowedMobAndLap" />
      <Block v-else />
    </div>
    <LaptopStudentLogin v-else />
  </div>
</template>

<script>
import { candidateSystemCheck, tokenForUserMobileLogin } from "../apiFunction";
import Block from "../components/mobileView/Block.vue";
import MobileStudentLogin from "../components/mobileView/MobileStudentLogin.vue";
import LaptopStudentLogin from "../components/StudentLogin.vue";
export default {
  name: "StudentLogin",
  data() {
    return {
      isLaptop: true,
      AllowedMobAndLap: true,
    };
  },
  components: {
    Block,
    MobileStudentLogin,
    LaptopStudentLogin,
  },
  mounted() {
    this.getdata();
    this.$store.commit("testVUEX/RESET_ALL_STATE");
    this.$store.commit("socketVUEX/RESET_ALL_STATE");
    this.$store.commit("sectionVUEX/RESET_ALL_STATE");
    this.$store.commit("questionVUEX/RESET_ALL_STATE");
    this.$store.commit("codingVUEX/RESET_ALL_STATE");
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize); // Add event listener for window resize
    console.log("mount");
    history.pushState(location.href, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize); // Remove event listener before component unmounts
  },
  methods: {
    checkScreenSize() {
      this.isLaptop = window.innerWidth >= 1024;
    },
    async getdata() {
      const res = await tokenForUserMobileLogin();
      if (res.status == 200) {
        localStorage.setItem("vue_token", res.data.token);
        localStorage.setItem("uuid", res.data.userid);
        this.getMobileVerification();
      }
    },
    async getMobileVerification() {
      try {
        let res = await candidateSystemCheck(`?uuid=${this.$route.query.uuid}`);
        console.log(res.data.data.Detail);
        this.AllowedMobAndLap = true;
      } catch (error) {
        this.AllowedMobAndLap = false;
      }
    },
  },
};
</script>

